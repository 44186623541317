import Combobo from "combobo";

export default {
  init() {
    const comboboCertification = new Combobo( {
      input:          "#certification-filter",
      list:           ".listbox.certification",
      options:        ".option", // qualified within `list`
      openClass:      "open",
      activeClass:    "active",
      selectedClass:  "selected",
      useLiveRegion:  false,
      multiselect:    false,
      noResultsText:  null,
      selectionValue: selecteds => selecteds.map( s => s.innerText.trim() ).join( ", " ),
      // eslint-disable-next-line max-len
      optionValue:    "underline", // wrap the matched portion of the option (if applicable) in a span with class "underline"
      filter:         "contains" // 'starts-with', 'equals', or funk
    } );
    const comboboTag = new Combobo( {
      input:          "#tag-filter",
      list:           ".listbox.tag",
      options:        ".option", // qualified within `list`
      openClass:      "open",
      activeClass:    "active",
      selectedClass:  "selected",
      useLiveRegion:  false,
      multiselect:    false,
      noResultsText:  null,
      selectionValue: selecteds => selecteds.map( s => s.innerText.trim() ).join( ", " ),
      // eslint-disable-next-line max-len
      optionValue:    "underline", // wrap the matched portion of the option (if applicable) in a span with class "underline"
      filter:         "contains" // 'starts-with', 'equals', or funk
    } );
    const comboboCountry = new Combobo( {
      input:          "#country-filter",
      list:           ".listbox.country",
      options:        ".option", // qualified within `list`
      openClass:      "open",
      activeClass:    "active",
      selectedClass:  "selected",
      useLiveRegion:  false,
      multiselect:    false,
      noResultsText:  null,
      selectionValue: selecteds => selecteds.map( s => s.innerText.trim() ).join( ", " ),
      // eslint-disable-next-line max-len
      optionValue:    "underline", // wrap the matched portion of the option (if applicable) in a span with class "underline"
      filter:         "contains" // 'starts-with', 'equals', or funk
    } );

	// arrow control
	let arrowCertification = document.querySelector('.arrow--certification');
	arrowCertification.addEventListener("click", function(e) {
		e.stopPropagation();
		if (!comboboCertification.isOpen) {
			comboboCertification.openList().goTo(comboboCertification.getOptIndex() || 0);
		}
		return false;
	});
	
	let arrowTag = document.querySelector('.arrow--tag');
	arrowTag.addEventListener("click", function(e) {
		e.stopPropagation();
		if (!comboboTag.isOpen) {
			comboboTag.openList().goTo(comboboTag.getOptIndex() || 0);
		}
		return false;
	});
	
	let arrowCountry = document.querySelector('.arrow--country');
	arrowCountry.addEventListener("click", function(e) {
		e.stopPropagation();
		if (!comboboCountry.isOpen) {
			comboboCountry.openList().goTo(comboboCountry.getOptIndex() || 0);
		}
		return false;
	});
    // clear all filters + search
    let clearFilters = document.getElementById( "clear-filters" );
    let search = document.getElementById( "search" );
    clearFilters.addEventListener( "click", ()=> {
      comboboCertification.reset();
      comboboTag.reset();
      comboboCountry.reset();
      search.value = "";
    } );
    comboboCertification.on('selection', function() {
      form.querySelector('#form_paged').value = 1;
      certificateForm();
  })
  comboboTag.on('selection', function() {
      form.querySelector('#form_paged').value = 1;
      certificateForm();
  })
  comboboCountry.on('selection', function() {
      form.querySelector('#form_paged').value = 1;
      certificateForm();
  })
    // open filters on mobile
    let expandButton = document.getElementById( "expand-filters" );
    let filters = document.querySelector( ".expand-filters" );
    expandButton.addEventListener( "click", ()=> {
      filters.classList.toggle( 'expanded' );
      if ( filters.classList.contains( "expanded" ) ) {
        expandButton.innerHTML = "Close Filters";
      }
      else {
        expandButton.innerHTML = "Expand Filters";
      }
      return false;
    } );
    let form = document.querySelector('#certificate-list-form');
    let certificateForm = () => {
      setTimeout(function() {
        let request = new XMLHttpRequest();
        request.responseType = 'json';
        request.open("POST", window.sage.ajax_url);
        request.onreadystatechange = function() {

            if (this.readyState === 4 && this.status === 200) {
                var content = document.querySelectorAll(".issue-info");
                var template = wp.template('companies-item');
               $('.results-grid').html(template(this.response));
                   var template2 = wp.template('pagination');
               $('.pagination-block').html(template2(this.response));
                   $('.results-header .caption').html(this.response.total_posts+" Results")
            }
        };
        var data = new FormData(form);
        request.send(data);
      }, 100)
    }
    certificateForm();
    // form.addEventListener('click', (event) => {
    //     event.preventDefault();
    //     certificateForm();
    // });
    let titleSearch = document.querySelector("#search");
    titleSearch.addEventListener("keyup", () => {
        if (titleSearch.value.length > 2 || titleSearch.value.length == 0) {
            certificateForm();
        }
    });
    $(document).on("click", '.pagination-block .no-bullets li a', function(event) {
      event.preventDefault();
      var paged = $(this).html();
      $(this).closest('form').find("#form_paged").val(paged).change();
      certificateForm();
  });
  $(document).on("click", '.pagination-button-next', function(event) {
      event.preventDefault();
      var paged = form.querySelector('#form_paged').value;
      if (paged) {
          paged = parseInt(paged) + 1;
      }
      form.querySelector('#form_paged').value = paged;
      certificateForm();
  });
  $(document).on("click", '.pagination-button-prev', function(event) {
      event.preventDefault();
      var paged = form.querySelector('#form_paged').value;
      if (paged) {
          paged = parseInt(paged) - 1;
      }
      form.querySelector('#form_paged').value = paged;
      certificateForm();
  });
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  }
};
