// a11y accordion
const accordionItems = document.querySelectorAll(".accordion-item");

if (accordionItems) {
	
	// accordion iterator
	accordionItems.forEach((item, index) => {
		const accordionChildren = item.children;
		accordionChildren[0].setAttribute("aria-controls", "sect-"+index);
		accordionChildren[0].setAttribute("id", "accordion-"+index);
		accordionChildren[1].setAttribute("aria-labelledby", "accordion-"+index);
		accordionChildren[1].setAttribute("id", "sect-"+index);
	});

	// accordion
	Array.prototype.slice.call(document.querySelectorAll(".accordion")).forEach(function (accordion) {

		var allowMultiple = accordion.hasAttribute("data-allow-multiple");
		var allowToggle = (allowMultiple) ? allowMultiple : accordion.hasAttribute("data-allow-toggle");
		var triggers = Array.prototype.slice.call(accordion.querySelectorAll(".accordion-trigger"));

		console.log(accordion);

		accordion.addEventListener("click", function (event) {
			var target = event.target;

			if (target.classList.contains("accordion-trigger")) {
				var isExpanded = target.getAttribute("aria-expanded") == "true";
				var active = accordion.querySelector("[aria-expanded='true']");

				if (!allowMultiple && active && active !== target) {
					active.setAttribute("aria-expanded", "false");
					document.getElementById(active.getAttribute("aria-controls")).setAttribute("hidden", "");
					if (!allowToggle)
						active.removeAttribute("aria-disabled");
				}
				if (!isExpanded) {
					target.setAttribute("aria-expanded", "true");
					document.getElementById(target.getAttribute("aria-controls")).removeAttribute("hidden");
					if (!allowToggle)
						target.setAttribute("aria-disabled", "true");
				}
				else if (allowToggle && isExpanded) {
					target.setAttribute("aria-expanded", "false");
					document.getElementById(target.getAttribute("aria-controls")).setAttribute("hidden", "");
				}
			}
			event.preventDefault();
		});

		triggers.forEach(trigger => {
			trigger.addEventListener("keyup", function (event) {
				var target = event.target;
				if( event.which == 9 ) {
					target.classList.add("focus");
				}
			});

			trigger.addEventListener("blur", function (event) {
				this.classList.remove("focus");
			});
		});

		accordion.addEventListener("keydown", function (event) {
			var target = event.target;
			var key = event.which.toString();
			var ctrlModifier = (event.ctrlKey && key.match(/33|34/));

			if (target.classList.contains("accordion-trigger")) {
				if (key.match(/38|40/) || ctrlModifier) {
					var index = triggers.indexOf(target);
					var direction = (key.match(/34|40/)) ? 1 : -1;
					var length = triggers.length;
					var newIndex = (index + length + direction) % length;
					triggers[newIndex].focus();
					event.preventDefault();
				}
				else if (key.match(/35|36/)) {
					switch (key) {
					case "36":
						triggers[0].focus();
						triggers[0].classList.add("focus");
						break;
					case "35":
						triggers[triggers.length - 1].focus();
						triggers[triggers.length - 1].classList.add("focus");
						break;
					}
					event.preventDefault();
				}
			}
		});

		if (!allowToggle) {
			var expanded = accordion.querySelector("[aria-expanded='true']");
			if (expanded)
				expanded.setAttribute("aria-disabled", "true");
		}

	});
}