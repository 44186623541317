import Swiper, { Pagination } from "swiper";

export default {
  init() {

    // banner flip
    const bannerWrapper = document.querySelector(".banner-news-events .wrapper");

    const flipBanner = () => {
      if (window.innerWidth < 1025) {
        if (!bannerWrapper.classList.contains("moved")) {
          bannerWrapper.classList.add("moved");
          const image = bannerWrapper.lastElementChild;
          bannerWrapper.insertAdjacentElement("afterbegin", image);
        }
      } else {
        if (bannerWrapper.classList.contains("moved")) {
          bannerWrapper.classList.remove("moved");
          const image = bannerWrapper.firstElementChild;
          bannerWrapper.insertAdjacentElement("beforeend", image);
        }
      }
    }

    flipBanner();

    window.addEventListener("resize", flipBanner);


    // news slider
    const slidesNum = document.querySelectorAll(".banner-news-events .swiper-slide").length;
    const postTitle = document.querySelectorAll(".banner-news-events .swiper-slide .text h2 a");
    const newsControls = document.querySelector(".banner-news-events .swiper-controls");

    // check number of slides
    let touch, doLoop;

    if (slidesNum < 2) {
        // only 1 slide, remove controls
        newsControls.remove();
        touch = false;
        doLoop = false;
    } else {
        // add controls to swiper
        touch = true;
        doLoop = true;
    }

    const newsSwiper = new Swiper(".banner-news-events .swiper", {
        loop: doLoop,
        simulateTouch: touch,
        spaceBetween: 0,
        slidesPerView: 1,
        modules: [Pagination],
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          renderBullet: function( index, className ) {
            return `<button class="${className} post-${( index + 1 )} copy-bold">${postTitle[index].innerHTML}</button>`;
          }
        },
        a11y: {
            prevSlideMessage: "Previous slide",
            nextSlideMessage: "Next slide",
            firstSlideMessage: "This is the first slide",
            lastSlideMessage: "This is the last slide",
            paginationBulletMessage: "Go to slide {{index}}",
        },
        on: {
            init: function (e) {

              // photos
              let index = this.realIndex + 1;
              const photo = document.querySelector(".banner-news-events .wrapper .right .photo img");
              let activePhoto = this.slides[index].dataset.img;
							
							if (photo) {
								photo.setAttribute("src", activePhoto);
							}

            },
            transitionStart: function () {

              // photos
              let index = this.realIndex + 1;
              const photo = document.querySelector(".banner-news-events .wrapper .right .photo img");
              let activePhoto = this.slides[index].dataset.img;
							if (photo) {
								photo.setAttribute("src", activePhoto);
							}

            },
            transitionEnd: function () {
            },
        },
    });

    // open filters on mobile
    let filterId = "filter-search";
    
    let filterInner = document.querySelector(".filter-search .inner");
    let expandButton = document.querySelector(`.${filterId} #expand-filters`);
  
    expandButton.addEventListener("click", () => {
      filterInner.classList.toggle("expanded");
      if (filterInner.classList.contains("expanded")) {
        expandButton.innerHTML = "Close Filters";
      } else {
        expandButton.innerHTML = "Expand Filters";
      }
    });

  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  }
};
