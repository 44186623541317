import Swiper, { Navigation, Pagination } from "swiper";

export default {
  init() {

    // banner flip
    const bannerWrapper = document.querySelector(".banner-secondary .wrapper");

    const flipBanner = () => {
      if (window.innerWidth < 1025) {
        if (!bannerWrapper.classList.contains("moved")) {
          bannerWrapper.classList.add("moved");
          const image = bannerWrapper.lastElementChild;
          bannerWrapper.insertAdjacentElement("afterbegin", image);
        }
      } else {
        if (bannerWrapper.classList.contains("moved")) {
          bannerWrapper.classList.remove("moved");
          const image = bannerWrapper.firstElementChild;
          bannerWrapper.insertAdjacentElement("beforeend", image);
        }
      }
    }

    flipBanner();

    window.addEventListener("resize", flipBanner);


    // value flip
    const valueWrapper = document.querySelector(".values .wrapper");

    const flipValue = () => {
      if (window.innerWidth < 1025) {
        if (!valueWrapper.classList.contains("moved")) {
          valueWrapper.classList.add("moved");
          const image = valueWrapper.lastElementChild;
          valueWrapper.insertAdjacentElement("afterbegin", image);
        }
      } else {
        if (valueWrapper.classList.contains("moved")) {
          valueWrapper.classList.remove("moved");
          const image = valueWrapper.firstElementChild;
          valueWrapper.insertAdjacentElement("beforeend", image);
        }
      }
    }

    if (valueWrapper) {
      flipValue();

      window.addEventListener("resize", flipValue);
    }


    // map flip
    const mapWrapper = document.querySelector(".about-global-institution .wrapper");

    const flipMap = () => {
      if (window.innerWidth < 1025) {
        if (!mapWrapper.classList.contains("moved")) {
          mapWrapper.classList.add("moved");
          const image = mapWrapper.lastElementChild;
          mapWrapper.insertAdjacentElement("afterbegin", image);
        }
      } else {
        if (mapWrapper.classList.contains("moved")) {
          mapWrapper.classList.remove("moved");
          const image = mapWrapper.firstElementChild;
          mapWrapper.insertAdjacentElement("beforeend", image);
        }
      }
    }

    flipMap();

    window.addEventListener("resize", flipMap);


    // history slider
    const slidesNum = document.querySelectorAll("#our-history .swiper-slide").length;
    const historyControls = document.querySelector("#our-history .swiper-controls");

    // check number of slides
    let touch, doLoop;

    if (slidesNum < 2) {
        // only 1 slide, remove controls
        historyControls.remove();
        touch = false;
        doLoop = false;
    } else {
        // add controls to swiper
        touch = true;
        doLoop = true;
    }

    const historySwiper = new Swiper("#our-history .swiper", {
        loop: doLoop,
        simulateTouch: touch,
        spaceBetween: 0,
        slidesPerView: 1,
        centeredSlides: true,
        modules: [Navigation, Pagination],
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        a11y: {
            prevSlideMessage: "Previous slide",
            nextSlideMessage: "Next slide",
            firstSlideMessage: "This is the first slide",
            lastSlideMessage: "This is the last slide",
            paginationBulletMessage: "Go to slide {{index}}",
        },
        on: {
            init: function (e) {

              // photos
              let index = this.realIndex + 1;
              const photo = document.querySelector(".history-slider .wrapper .image .photo img");
              let activePhoto = this.slides[index].dataset.img;
              photo.setAttribute("src", activePhoto);

            },
            transitionStart: function () {

              // photos
              let index = this.realIndex + 1;
              const photo = document.querySelector(".history-slider .wrapper .image .photo img");
              let activePhoto = this.slides[index].dataset.img;
              photo.setAttribute("src", activePhoto);

            },
            transitionEnd: function () {
            },
        },
    });

  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  }
};

