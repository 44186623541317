import Swiper, { Navigation, Pagination } from "swiper";
import Combobo from "combobo";

export default {
  init() {

    // resources slider
    const slidesNum = document.querySelectorAll(".featured-resources .swiper-slide").length;
    const resourcesControls = document.querySelector(".featured-resources .swiper-controls");

    // check number of slides
    let touch, doLoop;

    if (slidesNum < 4) {
        // only 1 slide, remove controls
        resourcesControls.remove();
        touch = false;
        doLoop = false;
    } else {
        // add controls to swiper
        touch = true;
        doLoop = true;
    }

    const resourcesSwiper = new Swiper(".featured-resources .swiper", {
        loop: doLoop,
        simulateTouch: touch,
        spaceBetween: 60,
        slidesPerView: 3,
        modules: [Navigation, Pagination],
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        a11y: {
            prevSlideMessage: "Previous slide",
            nextSlideMessage: "Next slide",
            firstSlideMessage: "This is the first slide",
            lastSlideMessage: "This is the last slide",
            paginationBulletMessage: "Go to slide {{index}}",
        },
        breakpoints: {
            300: {
                spaceBetween: 20,
                slidesPerView: 1,
                centeredSlides: false,
            },
            768: {
                spaceBetween: 40,
                slidesPerView: 2,
                centeredSlides: false,
            },
            1225: {
                spaceBetween: 80,
                slidesPerView: 3,
                centeredSlides: true,
            }
        },
        on: {
            init: function (e) {

              // accessibility
              $(".swiper-slide a").attr("tabindex", "-1");
              $(".swiper-slide-active a").attr("tabindex", "0");
              $(".swiper-slide-prev a").attr("tabindex", "0");
              $(".swiper-slide-next a").attr("tabindex", "0");

            },
            transitionStart: function () {

              // accessibility
              $(".swiper-slide a").attr("tabindex", "-1");
              $(".swiper-slide-active a").attr("tabindex", "0");
              $(".swiper-slide-prev a").attr("tabindex", "0");
              $(".swiper-slide-next a").attr("tabindex", "0");

            },
            transitionEnd: function () {
            },
        },
    });

    // resources grid
    let filterId = "filter-search-resources";

    const comboboType = new Combobo({
      input:          "#filter-1",
      list:           ".listbox.filter-1",
      options:        ".option", // qualified within `list`
      openClass:      "open",
      activeClass:    "active",
      selectedClass:  "selected",
      useLiveRegion:  false,
      multiselect:    true,
      noResultsText:  null,
      // eslint-disable-next-line max-len
      selectionValue: selecteds => selecteds.map( s => s.innerText.trim() ).join( ", " ),
      
      // eslint-disable-next-line max-len
      optionValue: "underline", // wrap the matched portion of the option (if applicable) in a span with class "underline"
      filter:      "contains" // 'starts-with', 'equals', or funk
    });

    // only init second filter if it
    const comboboTopic = new Combobo({
      input:          "#filter-2",
      list:           ".listbox.filter-2",
      options:        ".option", // qualified within `list`
      openClass:      "open",
      activeClass:    "active",
      selectedClass:  "selected",
      useLiveRegion:  false,
      multiselect:    true,
      noResultsText:  null,
      // eslint-disable-next-line max-len
      selectionValue: selecteds => selecteds.map( s => s.innerText.trim() ).join( ", " ),

      // eslint-disable-next-line max-len
      optionValue: "underline", // wrap the matched portion of the option (if applicable) in a span with class "underline"
      filter:      "contains" // 'starts-with', 'equals', or funk
    });

    // clear filters
    let clearFilters = document.querySelector(`.${filterId} #clear-filters`);

    clearFilters.addEventListener("click", () => {
      comboboTopic.reset();
      comboboType.reset();
    });

    // clear search
    let clearSearch = document.querySelector(`.${filterId} #clear-search`);
    let search = document.querySelector(`.${filterId} #search`);

    clearSearch.addEventListener("click", () => {
      search.value = "";
      if (form.querySelector('#form_paged').value > 1) {
        form.querySelector('#form_paged').value = 1;
      }
    });

    // open filters on mobile
    let filterInner = document.querySelector(`.${filterId} .inner`);
    let expandButton = document.querySelector(`.${filterId} #expand-filters`);
  
    expandButton.addEventListener("click", () => {
      filterInner.classList.toggle("expanded");
      if (filterInner.classList.contains("expanded")) {
        expandButton.innerHTML = "Close Filters";
      } else {
        expandButton.innerHTML = "Expand Filters";
      }
    });
 
    let form = document.querySelector('#resource-list-form');

    comboboType.on('selection', function() {
      form.querySelector('#form_paged').value = 1;
    });

    comboboTopic.on('selection', function() {
        form.querySelector('#form_paged').value = 1;
    });

    form.addEventListener('submit', (e) => {
        e.preventDefault();
        resourceForm();
    });

    let resourceForm = () => {
      setTimeout(function() {
          let request = new XMLHttpRequest();
          request.responseType = 'json';
          request.open("POST", window.sage.ajax_url);
          request.onreadystatechange = function() {
            if (this.readyState === 4 && this.status === 200) {
                var content = document.querySelectorAll(".issue-info");
                $('.results-grid').html(this.response.html);
                var template2 = wp.template('pagination');
                $('.pagination-block').html(template2(this.response));
                $('.results-header .caption').html(this.response.total_posts + " Results");
            }
          };
          var data = new FormData(form);
          request.send(data);
      }, 100)
    }

    resourceForm();

    $(document).on("click", '.pagination-block .no-bullets li a', function(event) {
      event.preventDefault();
      var paged = $(this).html();
      form.querySelector('#form_paged').value = paged;
      resourceForm();
    });

    $(document).on("click", '.pagination-button-next', function(event) {
        event.preventDefault();
        var paged = form.querySelector('#form_paged').value;
        if (paged) {
          paged = parseInt(paged) + 1;
        }
        form.querySelector('#form_paged').value = paged;
        resourceForm();
    });

    $(document).on("click", '.pagination-button-prev', function(event) {
        event.preventDefault();
        var paged = form.querySelector('#form_paged').value;
        if (paged) {
          paged = parseInt(paged) - 1;
        }
        form.querySelector('#form_paged').value = paged;
        resourceForm();
    });

    $(document).on("click", 'input#search', function(event) {
      if(form.querySelector('#form_paged').value > 1) {
        form.querySelector('#form_paged').value = 1;
      }
    });


  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  }
};
