import Swiper, { Navigation, Pagination } from "swiper";
// import Combobo from "combobo";
// import SimpleBar from "simplebar";
// import simpleParallax from "simple-parallax-js";
// import ClipboardJS from "clipboard";

export default {
  init() {

    // primary menu
    const menuToggles = document.querySelectorAll(".top-level-menu > li:not(.extra) > .dropdown-link")

    const toggleMenu = (e) => {
      const toggleBtn = e.currentTarget;
      if (toggleBtn.classList.contains("open")) {
        console.log("has open");
        toggleBtn.classList.remove("open")
        toggleBtn.setAttribute("aria-expanded", "false")
        toggleBtn.nextElementSibling.setAttribute("aria-hidden", "true")
      } else {
        menuToggles.forEach(toggle => {
          toggle.classList.remove("open")
          toggle.setAttribute("aria-expanded", "false")
          toggle.nextElementSibling.setAttribute("aria-hidden", "true")
        })
        toggleBtn.classList.add("open")
        toggleBtn.setAttribute("aria-expanded", "true")
        toggleBtn.nextElementSibling.setAttribute("aria-hidden", "false")
      }
    }

    menuToggles.forEach(toggle => {
      toggle.addEventListener("click", toggleMenu)
    })


    // menu check
    const menuCheck = (menuLinks) => {
      if (window.innerWidth < 1025) {
        menuLinks.forEach(link => {
          link.setAttribute("tabindex", "-1");
        });
      } else {
        menuLinks.forEach(link => {
          link.setAttribute("tabindex", "0");
        });
      }
    }


    // trap focus in menu
    const trapFocus = (element, menuOpen, hamburgerButton) => {
      let focusableEls = element.querySelectorAll("a[href]:not([disabled]), button:not([disabled])");
      const firstFocusableEl = hamburgerButton;
      const lastFocusableEl = focusableEls[focusableEls.length - 1];
      const KEYCODE_TAB = 9;
      
      element.addEventListener("keydown", function(e) {
        if (menuOpen === true) {
          let isTabPressed = (e.key === "Tab" || e.keyCode === KEYCODE_TAB);
          if (!isTabPressed) return;

          /* shift + tab */
          if (e.shiftKey && document.activeElement === firstFocusableEl) {
            lastFocusableEl.focus();
            e.preventDefault();
          /* tab */
          } else if (document.activeElement === lastFocusableEl) {
            firstFocusableEl.focus();
            e.preventDefault();
          }
        }
      });
    }
		
		const iconsMover = () => {
			  let iconsContainerTop = document.querySelector(".icons-container-top");
				let iconsContainerMenu = document.querySelector(".icons-container-menu");
				let icons = document.querySelector(".js-icons");
				let isInMenu = !!document.querySelector(".icons-container-menu .js-icons");
				let shouldBeInMenu = window.innerWidth <= 1024;
        // 1024
        if ( shouldBeInMenu && !isInMenu ) {
        // move it inside the menu div
          iconsContainerMenu.appendChild( icons );
        }
        else if ( !shouldBeInMenu && isInMenu ) {
        // move it inside the top div
          iconsContainerTop.appendChild( icons );
        }
		};
		iconsMover();
		addEventListener("resize", () => iconsMover());

    // hamburger
    const hamburger = () => {
      let menuOpen = false;
      const html = document.querySelector("html");
      const body = document.querySelector("body");
      const hamburgerButton = document.querySelector(".hamburger");
      const menu = document.getElementById("primary-menu");
      const logo = document.querySelector("header .logo");
      const mainCnt = document.querySelector("main");
      const footerCnt = document.querySelector("footer");
      let menuLinks = menu.querySelectorAll("a[href]:not([disabled]), button:not([disabled])");
      
      menuCheck(menuLinks);

      window.addEventListener("resize", () => menuCheck(menuLinks));

      hamburgerButton.addEventListener("click", () => {
        hamburgerButton.classList.toggle("is-active");
        menu.classList.toggle("open");
        body.classList.toggle("fixed");
        html.classList.toggle("fixed");

        if (hamburgerButton.classList.contains("is-active")) {
          menu.setAttribute("aria-expanded", "true");
          mainCnt.setAttribute("aria-hidden", "true");
          footerCnt.setAttribute("aria-hidden", "true");

          menuOpen = true;
          trapFocus(menu, menuOpen, hamburgerButton);
          menuLinks.forEach(link => {
            link.setAttribute("tabindex", "0");
          });
          logo.setAttribute("tabindex", "-1");

        } else {
          menu.setAttribute("aria-expanded", "false");
          mainCnt.setAttribute("aria-hidden", "false");
          footerCnt.setAttribute("aria-hidden", "false");
          menuOpen = false;
          menuLinks.forEach(link => {
            link.setAttribute("tabindex", "-1");
          });
          logo.setAttribute("tabindex", "0");
        }
      });
    }

    hamburger();


    // a11y
    const a11yMenu = () => {
        
      if (window.innerWidth > 1024) {
              
          // primary nav desktop tab
          const nav_menu_links = document.querySelectorAll("#menu-primary-navigation > li > a, #menu-primary-navigation > li > button");

          if (nav_menu_links !== null) {

              for (let nml_index = 0; nml_index < nav_menu_links.length; nml_index++) {

                  nav_menu_links[nml_index].addEventListener("focus", function () {

                      const link_parent = this.parentElement;
                      link_parent.querySelector(".dropdown-link").setAttribute("aria-expanded", "false");
                      link_parent.querySelector(".dropdown-link").classList.remove("open");
                      link_parent.querySelector(".submenu").setAttribute("aria-hidden", "true");

                  });

              }

          }

          // primary nav desktop sub menu tab
          const primary_menu = document.getElementById("menu-primary-navigation");
          const submenu_links = document.querySelectorAll("#menu-primary-navigation > li > .submenu li a");

          if (submenu_links !== null) {

              for (let sbl_index = 0; sbl_index < submenu_links.length; sbl_index++) {

                  submenu_links[sbl_index].addEventListener("blur", function () {

                      const link_parent = this.parentElement.parentElement;

                      setTimeout(function () {

                          let target = document.activeElement;
                          let target_parent = target.parentElement.parentElement;

                          if (target_parent === primary_menu) {
          
                              link_parent.closest(".submenu").previousElementSibling.setAttribute("aria-expanded", "false");
                              link_parent.closest(".submenu").previousElementSibling.classList.remove("open");
                              link_parent.closest(".submenu").setAttribute("aria-hidden", "true");
          
                          }

                      }, 100);

                  });

              }

          }


      }

    }

    a11yMenu();

    window.addEventListener("resize", a11yMenu);


    // pacMan menu

    // create extra menu item
    const menu = document.getElementById("menu-primary-navigation");
    const navHeightMax = menu.clientHeight;

    // make extra li
    const extraDropDownLI = document.createElement("li");
    extraDropDownLI.classList.add("extra");
    extraDropDownLI.setAttribute("data-width", 121);
    menu.insertAdjacentElement("beforeend", extraDropDownLI);

    // make more button
    const moreBtn = document.createElement("button");
    moreBtn.classList.add("more-dropdown-link");
    moreBtn.setAttribute("aria-expanded", "false");
    moreBtn.setAttribute("aria-controls", "more-submenu");
    moreBtn.innerText = "More";
    extraDropDownLI.insertAdjacentElement("afterbegin", moreBtn);

    // make more submenu
    const extraDropDownLI_UL = document.createElement("ul");
    extraDropDownLI_UL.setAttribute("id", "more-submenu");
    extraDropDownLI_UL.classList.add("more-submenu");
    extraDropDownLI_UL.setAttribute("aria-hidden", "true");
    extraDropDownLI.insertAdjacentElement("beforeend", extraDropDownLI_UL);    

    const pacManMenu = () => {

      if (window.innerWidth > 1024) {

        let navWidth = 0;
        let navHeight = menu.clientHeight;
        const menuItems = document.querySelectorAll(".top-level-menu > li:not(.extra)");
        const moreWidthItem = menuItems[menuItems.length - 1];
        const moreWidth = moreWidthItem.clientWidth;

        menuItems.forEach(item => {
          navWidth += item.clientWidth;
        })

        let availablespace = menu.clientWidth - moreWidth;
        
        if ((navWidth) >= availablespace || navHeight != navHeightMax) {

          let lastitem = menuItems[menuItems.length - 1];
          lastitem.setAttribute('data-width', lastitem.offsetWidth);
          extraDropDownLI_UL.insertAdjacentElement("afterbegin", lastitem);

          pacManMenu();

        } else {

          const firstMoreElement = extraDropDownLI_UL.firstElementChild;

          if (firstMoreElement) {
            let returnItemWidth = parseInt(firstMoreElement.dataset.width);
            if ((navWidth + returnItemWidth) <= availablespace) {
              extraDropDownLI.insertAdjacentElement("beforebegin", firstMoreElement)
            }
          }

        }
          
        if (extraDropDownLI_UL.querySelectorAll("li").length > 0) {
          extraDropDownLI.style.display = "block";
        } else {
          extraDropDownLI.style.display = "none";
        }

      } else {

        const extraDropDownItems = document.querySelectorAll(".more-submenu > li");

        extraDropDownItems.forEach(extraItem => {
          extraDropDownLI.insertAdjacentElement("beforebegin", extraItem)
        })

      }

    }

    pacManMenu();
  
    window.addEventListener("resize", pacManMenu);


    // more submenu toggle
    const moreToggle = document.querySelector(".extra > .more-dropdown-link")

    const toggleMoreMenu = (e) => {
      const moreToggle = e.currentTarget;
      if (moreToggle.classList.contains("more-dropdown-link")) {
        if (moreToggle.classList.contains("open")) {
          moreToggle.classList.remove("open")
          moreToggle.setAttribute("aria-expanded", "false")
          moreToggle.nextElementSibling.setAttribute("aria-hidden", "true")
        } else {
          moreToggle.classList.add("open")
          moreToggle.setAttribute("aria-expanded", "true")
          moreToggle.nextElementSibling.setAttribute("aria-hidden", "false")
        }
      }
    }

    moreToggle.addEventListener("click", toggleMoreMenu)


    // toggle language drop down
    const lngDDBtn = document.querySelector(".lang-toggle");
    //const lngDDLinks = document.querySelectorAll("header .top .icons .dropdown ul li a");
		const lngDDLinks = document.querySelectorAll("header .top .icons .dropdown a");
    const lngDD = lngDDBtn.nextElementSibling;
    lngDD.setAttribute("id", "lang-dd");
    lngDD.setAttribute("aria-hidden", "true");

    const toggleLngDD = (e) => {
      const btn = e.currentTarget;
      if (btn.classList.contains("open")) {
        btn.classList.remove("open");
        btn.setAttribute("aria-expanded", "false");
        btn.nextElementSibling.setAttribute("aria-hidden", "true");
      } else {
        btn.classList.add("open");
        btn.setAttribute("aria-expanded", "true");
        btn.nextElementSibling.setAttribute("aria-hidden", "false");
      }
    }

    const closeLngDD = () => {
      console.log("close");
      lngDDBtn.classList.remove("open");
      lngDDBtn.setAttribute("aria-expanded", "false");
      lngDDBtn.nextElementSibling.setAttribute("aria-hidden", "true");
    }

    lngDDBtn.addEventListener("click", toggleLngDD);

    lngDDLinks.forEach(link => {
      link.addEventListener("click", closeLngDD);
    });


    // single banner flip
    const singleBannerWrapper = document.querySelector(".banner-detail .wrapper");

    const flipSingleBanner = () => {
      if (window.innerWidth < 1025) {
        if (!singleBannerWrapper.classList.contains("moved")) {
          singleBannerWrapper.classList.add("moved");
          const image = singleBannerWrapper.lastElementChild;
          singleBannerWrapper.insertAdjacentElement("afterbegin", image);
        }
      } else {
        if (singleBannerWrapper.classList.contains("moved")) {
          singleBannerWrapper.classList.remove("moved");
          const image = singleBannerWrapper.firstElementChild;
          singleBannerWrapper.insertAdjacentElement("beforeend", image);
        }
      }
    }

    if (singleBannerWrapper) {
      flipSingleBanner();

      window.addEventListener("resize", flipSingleBanner);
    }


    // gallery slider
    const slidesNum = document.querySelectorAll("#image-gallery .swiper-slide").length;
    const galleryControls = document.querySelector("#image-gallery .swiper-controls");

    if (slidesNum) {
      // check number of slides
      let touch, doLoop;

      if (slidesNum < 2) {
          // only 1 slide, remove controls
          galleryControls.remove();
          touch = false;
          doLoop = false;
      } else {
          // add controls to swiper
          touch = true;
          doLoop = true;
      }

      const gallerySwiper = new Swiper("#image-gallery .swiper", {
          loop: doLoop,
          simulateTouch: touch,
          spaceBetween: 40,
          slidesPerView: 2.75,
          modules: [Navigation, Pagination],
          navigation: {
              nextEl: "#image-gallery .swiper-button-next",
              prevEl: "#image-gallery .swiper-button-prev",
          },
          pagination: {
              el: "#image-gallery .swiper-pagination",
              clickable: true,
          },
          a11y: {
              prevSlideMessage: "Previous slide",
              nextSlideMessage: "Next slide",
              firstSlideMessage: "This is the first slide",
              lastSlideMessage: "This is the last slide",
              paginationBulletMessage: "Go to slide {{index}}",
          },
          breakpoints: {
            300: {
              spaceBetween: 20,
              slidesPerView: 1.25,
            },
            768: {
              spaceBetween: 40,
              slidesPerView: 2.75,
            }
          },
          on: {
              init: function (e) {
              },
              transitionStart: function () {
              },
              transitionEnd: function () {
              },
          },
      });
    }


    // video flip
    const videoWrapper = document.querySelector(".video-module .wrapper");

    const flipVideo = () => {
      if (window.innerWidth < 1025) {
        if (!videoWrapper.classList.contains("moved")) {
          videoWrapper.classList.add("moved");
          const image = videoWrapper.lastElementChild;
          videoWrapper.insertAdjacentElement("afterbegin", image);
        }
      } else {
        if (videoWrapper.classList.contains("moved")) {
          videoWrapper.classList.remove("moved");
          const image = videoWrapper.firstElementChild;
          videoWrapper.insertAdjacentElement("beforeend", image);
        }
      }
    }

    if (videoWrapper) {
      flipVideo();

      window.addEventListener("resize", flipVideo);
    }






    












   




    // media queries
    // let mobile = window.matchMedia( "(max-width: 1199px)" );
    // let desktop = window.matchMedia( "(min-width: 1200px)" );

    // check for Safari 3.0+ "[object HTMLElementConstructor]"
    // const isSafari =
    //  /constructor/i.test( window.HTMLElement ) ||
    //  ( function( p ) {
    //    return p.toString() === "[object SafariRemoteNotification]";
    //  } )(
    //    !window["safari"] ||
    //      ( typeof safari !== "undefined" && safari.pushNotification )
    //  );
    // if ( isSafari == true ) {
    //   $( "html" ).addClass( "safari" );
    // }










    // cookie banner close button
    // let cookieBannerClose = () => {
    //   let cookieBanner = $( ".cookie-banner" );
      
    //   if ( $( cookieBanner ).length ) {
    //     let closeCookieBanner = $( "#close-cookie-banner" );
    //     closeCookieBanner.on( "click", function() {
    //       cookieBanner.removeClass( "visible" );
    //     } );
    //   }
    // };
    // cookieBannerClose();










    /* BEGIN SKIP LINKS */
    // skip link scroll to section
    // const skipToAnchor = aid => {
    //   var aTag = $( aid );
    //   var focus = true;
    //   $( "html,body" ).animate(
    //     {
    //       scrollTop: aTag.offset().top
    //     },
    //     "slow"
    //   );
    //   var first_child = $( aTag.children()[0] );
    //   var tag = first_child.prop( "tagName" ).toLowerCase();

    //   if ( tag !== "a" && tag !== "button" && tag !== "input" && tag !== "textarea" ) {
    //     if ( first_child.attr( "tabIndex" ) !== undefined ) {
    //       first_child.attr( "tabIndex", -1 ).focus();
    //       first_child.removeAttr( "tabIndex" );
    //     }
    //     else {
    //       first_child.focus();
    //     }
    //   }
    //   else {
    //     first_child.focus();
    //   }
    // };

    // create skip links
    // const skipLinks = () => {
    //   $( "section" ).each( function() {
    //     const id = $( this ).attr( "id" );
    //     if ( id !== undefined ) {
    //       // Use the section id to create a label for the items in the skip link list
    //       var sectionNameArray = id.split( "-" );
    //       var sectionName = "";
    //       for ( var i = 0; i < sectionNameArray.length; i++ ) {
    //         var str = sectionNameArray[i];
    //         str = str.toLowerCase().replace( /\b[a-z]/g, function( letter ) {
    //           return letter.toUpperCase();
    //         } );
    //         sectionName += str;
    //         if ( i < sectionNameArray.length - 1 ) {
    //           sectionName += " ";
    //         }
    //       }
    //       var skiplink =
    //                     "<li><a href='#" +
    //                     id +
    //                     "' class='text-link'>Skip to " +
    //                     sectionName +
    //                     "</a></li>";
    //       $( ".skiplinks ul" ).append( skiplink );
    //     }
    //   } );

    //   const skipLinkContainer = $( ".skiplinks" ),
    //     skipLink = $( ".skiplinks a" );

    //   skipLink.on( "focus", function() {
    //     skipLinkContainer.addClass( "show" );
    //   } );

    //   skipLink.on( "blur", function() {
    //     skipLinkContainer.removeClass( "show" );
    //   } );

    //   skipLink.on( "click", function( e ) {
    //     e.preventDefault();
    //     skipToAnchor( $( this ).attr( "href" ) );
    //   } );
    // };

    // skipLinks();

    // Scroll To
    // ( function() {
    //   $( ".scrollto" ).on( "click", function( e ) {
    //     e.preventDefault();
    //     var section = $( this ).attr( "href" );
    //     $( "html, body" ).animate( { scrollTop: $( section ).offset().top }, 1000 );
    //   } );
    // } )();
    /* END SKIP LINKS */


    // add active on geo translate
    // ( function() {
    //   $( ".glink" ).on( "click", function( e ) {
    //      $('.glink').removeClass('active');
    //      $(this).addClass('active');
    //   } );
    // } )();
    /* END SKIP LINKS */









    // smooth scrolling
    // let smoothScroll = () => {
    //   //Smooth scrolling with anchor links
    //   $( document ).on( "click", "a[href^=\"#\"]", function( event ) {
    //     event.preventDefault();
    
    //     $( "html, body" ).animate( {
    //       scrollTop: $( $.attr( this, "href" ) ).offset().top
    //     }, 500 );
    //   } );
    // };

    // smoothScroll();






    // let printPage = () => {
    //   let printButton = document.getElementById( "print-page" );
    //   if ( printButton ) {
    //     printButton.addEventListener( "click", ()=> {
    //       window.print();
    //     } );
    //   }
    // };
    // printPage();


    
    /**
     * execCommand is listed as depreciated with no indication of alternative,
     * so using ClipboardJS in the meantime
     * @param data-clipboard-text
     * @uses ClipboardJS
     * @link https://github.com/zenorocha/clipboard.js
     */
    //const socialShareLink = () => new ClipboardJS(document.querySelectorAll('.clipboard-target'));
    //socialShareLink();

    // var clipboardDemos = new ClipboardJS('.clipboard-target')
    //   clipboardDemos.on('success', function (e) {
    //       e.clearSelection()
    //       showTooltip()
    //   });
    //   function showTooltip(){
    //     $('.tool').addClass('visible');
    //     setTimeout(function () {
    //         $('.tool').removeClass('visible');
    //     }, 2000)
    //   }

    //   $('body').on('hover', '.clipboard-target', function () {
    //     $('.copy-link').addClass('visible');
    //   }, function(){
    //       $('.copy-link').removeClass('visible');
    //   })









    // let filterSearch = () => {
    //   let filterSearchModule = document.querySelectorAll( ".filter-search" );
     
    //   // add custom class
    //   if ( filterSearchModule.length ) {
    //     for ( let i = 0; i < filterSearchModule.length; i++ ) {
    //       filterSearchModule[i].classList.add( `filters-${i + 1}` );
    //     }
    //   }

    //   filterSearchModule.forEach( filter=> {
    //     let filterId = filter.classList[1];
        
    //     // Combobo moved to FilterBar.vue setupCombo method and clearFilters method

    //     // clear search
    //     let clearSearch = document.querySelector( `.${filterId} #clear-search` );
    //     let search = document.querySelector( `.${filterId} #search` );
    //     clearSearch.addEventListener( "click", ()=> {
    //       search.value = "";
    //     } );

    //     // open filters on mobile
    //     let filterInner = document.querySelector( ".filter-search .inner" );
    //     let expandButton = document.querySelector( `.${filterId} #expand-filters` );
     
    //     expandButton.addEventListener( "click", ()=> {
    //       filterInner.classList.toggle( "expanded" );
    //       if ( filterInner.classList.contains( "expanded" ) ) {
    //         expandButton.innerHTML = "Close Filters";
    //       }
    //       else {
    //         expandButton.innerHTML = "Expand Filters";
    //       }
    //     } );
    //   } );
    // };
    // filterSearch();







    // reveal on scroll
    // const revealElements = document.querySelectorAll( ".reveal" );

    // function reveal() {
    //   revealElements.forEach( revealElement => {
    //     const revealAt = ( window.scrollY + window.innerHeight ) - revealElement.offsetHeight / 2;
    //     const itemPosition = window.pageYOffset + revealElement.getBoundingClientRect().top;
    //     const itemBottom = itemPosition + revealElement.offsetHeight;
    //     const isHalfShown = revealAt > itemPosition;
    //     const isNotScrolledPast = window.scrollY < itemBottom;
    //     if ( isHalfShown && isNotScrolledPast ) {
    //       revealElement.classList.add( "on" );
    //     }
    //     // disable if you don't want it to reanimate
    //     // else {
    //     //   revealElement.classList.remove( "on" );
    //     // }
    //   } );
    // }

    // window.addEventListener( "scroll", reveal );
    

  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

  }
};
