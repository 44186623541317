export default {
  init() {

    // open filters on mobile
    let filterId = "filter-search";

    const filterInner = document.querySelector(".filter-search .inner");
    const expandButton = document.querySelector(`.${filterId} #expand-filters`);
  
    if (expandButton) {
      expandButton.addEventListener("click", () => {
        filterInner.classList.toggle("expanded");
        if (filterInner.classList.contains("expanded")) {
          expandButton.innerHTML = "Close Filters";
        } else {
          expandButton.innerHTML = "Expand Filters";
        }
      });
    }


    // share question
    const shareQuestion = document.querySelectorAll(".questions .question-indiv .content .share button"); 

    if (shareQuestion) {
      shareQuestion.forEach(shareLink => {

        // add tooltip on hover
        shareLink.addEventListener("mouseenter", e => {
          let currentQuestion = e.currentTarget;
          let clickToCopy = currentQuestion.nextElementSibling;
          clickToCopy.classList.add("visible");
        });

        // remove tooltip when done hovering
        shareLink.addEventListener("mouseleave", e => {
          let currentQuestion = e.currentTarget;
          let clickToCopy = currentQuestion.nextElementSibling;
          clickToCopy.classList.remove("visible");
        });

        // copy link
        shareLink.addEventListener("click", e => {
          e.preventDefault;
          
          let currentQuestion = e.currentTarget;
          console.log(currentQuestion);
          let clickToCopy = currentQuestion.nextElementSibling;
          clickToCopy.classList.remove("visible");

          // show the tooltip telling user link copied
          let linkCopied = clickToCopy.nextElementSibling;
          linkCopied.classList.add("visible");

          // remove tooltip after 2 seconds
          setTimeout(() => {
            linkCopied.classList.remove("visible");
          }, 2000);

        });

      });
    }


    // team count
    const teamGrid = document.querySelectorAll(".team-grid");

    if (teamGrid) {

      teamGrid.forEach(grid => {
        const gridItems = grid.querySelectorAll(".team-indiv");

        gridItems.forEach((item, index) => {
          index++;
          if (index % 5 === 0 || index % 6 === 0 || index % 7 === 0 || index % 8 === 0) {
            item.classList.add("reverse");
          }
        });

      });

    }

  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  }
};
