export default {
  init() {

    // section flip
    const sectionWrapper = document.querySelector(".txt-ft-two-col.flipped .wrapper");

    const flipSection = () => {
      if (window.innerWidth < 768) {
        if (!sectionWrapper.classList.contains("moved")) {
            sectionWrapper.classList.add("moved");
            const image = sectionWrapper.lastElementChild;
            sectionWrapper.insertAdjacentElement("afterbegin", image);
        }
      } else {
        if (sectionWrapper.classList.contains("moved")) {
            sectionWrapper.classList.remove("moved");
            const image = sectionWrapper.firstElementChild;
            sectionWrapper.insertAdjacentElement("beforeend", image);
        }
      }
    }

    flipSection();

    window.addEventListener("resize", flipSection);

  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  }
};

