import Combobo from "combobo";

export default {
    init() {
        const comboboTop = new Combobo({
            input: "#top-filter",
            list: ".listbox.top",
            options: ".option", // qualified within `list`
            openClass: "open",
            activeClass: "active",
            selectedClass: "selected",
            useLiveRegion: false,
            multiselect: false,
            noResultsText: null,
            selectionValue: selecteds => selecteds.map(s => s.innerText.trim()).join(", "),
            // eslint-disable-next-line max-len
            optionValue: "underline", // wrap the matched portion of the option (if applicable) in a span with class "underline"
            filter: "contains" // 'starts-with', 'equals', or funk
        });

        // when option is selected, enable secondary options
        const secondFilter = document.getElementById('second-filter');
        if (secondFilter) {
            let secondaryOptions = document.querySelector(".filter__dropdown.secondary .combo-wrap");
            comboboTop.on("selection", () => {
                secondaryOptions.classList.add("enabled");
            });
            const comboboSecond = new Combobo({
                input: "#second-filter",
                list: ".listbox.second",
                options: ".option", // qualified within `list`
                openClass: "open",
                activeClass: "active",
                selectedClass: "selected",
                useLiveRegion: false,
                multiselect: false,
                noResultsText: null,
                selectionValue: selecteds => selecteds.map(s => s.innerText.trim()).join(", "),
                // eslint-disable-next-line max-len
                optionValue: "underline", // wrap the matched portion of the option (if applicable) in a span with class "underline"
                filter: "contains" // 'starts-with', 'equals', or funk
            });
        }

        const comboboMarketplace = new Combobo({
            input: "#marketplace-filter",
            list: ".listbox.marketplace",
            options: ".option", // qualified within `list`
            openClass: "open",
            activeClass: "active",
            selectedClass: "selected",
            useLiveRegion: false,
            multiselect: false,
            noResultsText: null,
            selectionValue: selecteds => selecteds.map(s => s.innerText.trim()).join(", "),
            // eslint-disable-next-line max-len
            optionValue: "underline", // wrap the matched portion of the option (if applicable) in a span with class "underline"
            filter: "contains" // 'starts-with', 'equals', or funk
        });

        const comboboCountry = new Combobo({
            input: "#country-filter",
            list: ".listbox.country",
            options: ".option", // qualified within `list`
            openClass: "open",
            activeClass: "active",
            selectedClass: "selected",
            useLiveRegion: false,
            multiselect: true,
            noResultsText: null,
            // eslint-disable-next-line max-len
            selectionValue: selecteds => selecteds.map(s => s.innerText.trim()).join(";"),
            // eslint-disable-next-line max-len
            optionValue: "underline", // wrap the matched portion of the option (if applicable) in a span with class "underline"
            filter: "contains" // 'starts-with', 'equals', or funk
        });

        const comboboCompany = new Combobo({
            input: "#company-filter",
            list: ".listbox.company",
            options: ".option", // qualified within `list`
            openClass: "open",
            activeClass: "active",
            selectedClass: "selected",
            useLiveRegion: false,
            multiselect: true,
            noResultsText: null,
            // eslint-disable-next-line max-len
            selectionValue: selecteds => selecteds.map(s => s.innerText.trim()).join(";"),

            // eslint-disable-next-line max-len
            optionValue: "underline", // wrap the matched portion of the option (if applicable) in a span with class "underline"
            filter: "contains" // 'starts-with', 'equals', or funk
        });
        let form = document.querySelector('#products-list-form');
        form.addEventListener('submit', (e) => {
            e.preventDefault();
            console.log('this should prevent the form from submitting but doesnt.');
        });
        let productForm = () => {
            setTimeout(function() {
                let request = new XMLHttpRequest();
                request.responseType = 'json';
                request.open("POST", window.sage.ajax_url);
                request.onreadystatechange = function() {

                    if (this.readyState === 4 && this.status === 200) {
                        var template = wp.template('products-item');
                        $('.results-grid').html(template(this.response));
                        var template2 = wp.template('pagination');
                        $('.pagination-block').html(template2(this.response));
                        $('.results-header .caption').html(this.response.total_posts + " Results")
                    }
                };
                var data = new FormData(form);
                request.send(data);
            }, 100)
        }
        productForm();
        comboboTop.on('selection', function() {
            form.querySelector('#form_paged').value = 1;
            productForm();
        })
        if (secondFilter) {
            comboboSecond.on('selection', function () {
                form.querySelector('#form_paged').value = 1;
                productForm();
            })
        }
        comboboMarketplace.on('selection', function() {
            form.querySelector('#form_paged').value = 1;
            productForm();
        })
        comboboCountry.on('selection', function() {
            form.querySelector('#form_paged').value = 1;
            productForm();
        })
        comboboCountry.on('deselection', function() {
            form.querySelector('#form_paged').value = 1;
            productForm();
        })
        comboboCompany.on('selection', function() {
            form.querySelector('#form_paged').value = 1;
            productForm();
        })
        comboboCompany.on('deselection', function() {
            form.querySelector('#form_paged').value = 1;
            productForm();
        })

        // clear all filters + search
        let titleSearch = document.querySelector("#search");
        titleSearch.addEventListener("keyup", () => {
            if (titleSearch.value.length > 2 || titleSearch.value.length === 0) {
                productForm();
            }
        });
        let clearFilters = document.getElementById("clear-filters");
        let search = document.getElementById("search");
        clearFilters.addEventListener("click", () => {
            comboboTop.reset();
            if (secondFilter) {
                comboboSecond.reset();
                secondaryOptions.classList.remove("enabled");
            }
            comboboMarketplace.reset();
            comboboCountry.reset();
            comboboCompany.reset();
            search.value = "";
            productForm();
        });

        // open filters on mobile
        const expandButton = document.getElementById("expand-filters");
        let filters = document.querySelector(".expand-filters");
        expandButton.addEventListener("click", () => {
            filters.classList.toggle("expanded");
            if (filters.classList.contains("expanded")) {
                expandButton.innerHTML = "Close Filters";
            } else {
                expandButton.innerHTML = "Expand Filters";
            }
        });
        $(document).on("click", '.pagination-block .no-bullets li a', function(event) {
            event.preventDefault();
            var paged = $(this).html();
            $(this).closest('form').find("#form_paged").val(paged).change();
            productForm();
        });
        $(document).on("click", '.pagination-button-next', function(event) {
            event.preventDefault();
            var paged = form.querySelector('#form_paged').value;
            if (paged) {
                paged = parseInt(paged) + 1;
            }
            form.querySelector('#form_paged').value = paged;
            productForm();
        });
        $(document).on("click", '.pagination-button-prev', function(event) {
            event.preventDefault();
            var paged = form.querySelector('#form_paged').value;
            if (paged) {
                paged = parseInt(paged) - 1;
            }
            form.querySelector('#form_paged').value = paged;
            productForm();
        });
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    }
};
