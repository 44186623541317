import Swiper, { Navigation, Pagination } from "swiper";

export default {
  init() {

    const slidesNum = document.querySelectorAll("#latest-news .swiper-slide").length;
    const heroControls = document.querySelector("#latest-news .swiper-controls");

    // check number of slides
    let touch, doLoop;

    if (slidesNum < 4) {
        // only 1 slide, remove controls
        heroControls.remove();
        touch = false;
        doLoop = false;
    } else {
        // add controls to swiper
        touch = true;
        doLoop = true;
    }

    const newsSwiper = new Swiper("#latest-news .swiper", {
        loop: doLoop,
        simulateTouch: touch,
        spaceBetween: 80,
        slidesPerView: 3,
        centeredSlides: true,
        modules: [Navigation, Pagination],
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        a11y: {
            prevSlideMessage: "Previous slide",
            nextSlideMessage: "Next slide",
            firstSlideMessage: "This is the first slide",
            lastSlideMessage: "This is the last slide",
            paginationBulletMessage: "Go to slide {{index}}",
        },
        breakpoints: {
            300: {
                spaceBetween: 20,
                slidesPerView: 1,
                centeredSlides: false,
            },
            768: {
                spaceBetween: 40,
                slidesPerView: 2,
                centeredSlides: false,
            },
            1225: {
                spaceBetween: 80,
                slidesPerView: 3,
                centeredSlides: true,
            }
        },
        on: {
            init: function (e) {
                // accessibility
                $(".swiper-slide a").attr("tabindex", "-1");
                $(".swiper-slide-active a").attr("tabindex", "0");
                $(".swiper-slide-prev a").attr("tabindex", "0");
                $(".swiper-slide-next a").attr("tabindex", "0");
            },
            transitionStart: function () {
                // accessibility
                $(".swiper-slide a").attr("tabindex", "-1");
                $(".swiper-slide-active a").attr("tabindex", "0");
                $(".swiper-slide-prev a").attr("tabindex", "0");
                $(".swiper-slide-next a").attr("tabindex", "0");
            },
            transitionEnd: function () {
            },
        },
    });

  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  }
};
